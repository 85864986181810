import { TipoIncidente } from "./tipo-incidente.model";
import Paciente from "./paciente.model";
import { EnumTipoDano, TipoDano } from "./tipo-dano.model";

export class FiltroGerirNotificacoes {
    tipoNotificacao: TipoIncidente
    dataInicial: Date
    dataFinal: Date

    constructor() {}
}

export class FiltroInvestigacoes {
    tipoNotificacao: TipoIncidente
    paciente: Paciente
    dataInicial: Date
    dataFinal: Date
    isInvestAUx: boolean

    constructor(){}
}

export class FiltroInvestigacoesPacienteString {
    tipoNotificacao: TipoIncidente
    paciente: string
    dataInicial: Date
    dataFinal: Date
    situacao: string
    tipoDano: string
    protocolo: string
    setor: number
    hospital: number

    constructor(){}
}

export class FiltroUsuarios {
    nome: string
    email: string
    telefone: string
    estabelecimento: number
    perfil: number
    usuarioses: boolean
    ativo: boolean

    constructor(){}
}