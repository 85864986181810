import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SIGIH_API } from "../app.api";
import { SetorLeito } from "../model/setor-leito";

@Injectable({
  providedIn: "root",
})
export class SetorLeitoService {
  constructor(private http: HttpClient) {}

  getSetoresLeitosByIdSetor(idSetor: number) {
    return this.http.get<SetorLeito[]>(`${SIGIH_API}/setor-leito/${idSetor}`);
  }

  createSetorLeito(setorLeito: SetorLeito) {
    return this.http.post<SetorLeito>(`${SIGIH_API}/setor-leito`, setorLeito);
  }

  deleteSetorLeito(setorLeito: SetorLeito) {
    return this.http.delete<void>(`${SIGIH_API}/setor-leito/${setorLeito.id}`);
  }

  async getQtdLeito(idSetor: number) {
    return this.http
      .get<number>(`${SIGIH_API}/setor-leito/leitos-ativos/${idSetor}`)
      .toPromise();
  }
}
